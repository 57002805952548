<template>
    <div style="font-family: 'Montserrat';">
        <div style="background:#ececec88;">
            <v-app-bar height="40" flat class="hidden-sm-and-down"
            style="background:transparent;width:90%;margin:0 auto;display:block;color:#22354b">
                <v-btn text small href="mailto:info@ficenca.com"
                style="font-size:12px;
                text-transform:none;color:#222222;letter-spacing:0px;
                ">
                <i class="fas fa-envelope pr-2" style="font-size:10px;"></i>
                <strong>info@ficenca.com</strong>
                </v-btn>


                <v-btn text small href="https://goo.gl/maps/DddbeYdvZkqiDzXf8" target="_blank"
                style="font-size:12px;
                text-transform:none;color:#222222;letter-spacing:0px;
                ">
                <i class="fas fa-location-arrow pr-2" style="font-size:10px;"></i>
                <strong>Dirección</strong>
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://facebook.com/ficencacostarica`">
                    <strong><i class="fab fa-facebook-f"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://instagram.com/ficencacostarica`">
                    <strong><i class="fab fa-instagram"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://www.linkedin.com/company/ficencacr/`">
                    <i class="fab fa-linkedin-in"></i>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://api.whatsapp.com/send?phone=50670188845`">
                    <strong><i class="fab fa-whatsapp"></i></strong>
                </v-btn>
            </v-app-bar>

            <v-app-bar height="40" flat class="hidden-md-and-up"
            style="background:transparent;width:90%;margin:0 auto;display:block;color:#165198">

                <div style="margin:0 auto;display:block;">
                    <v-btn icon text small href="mailto:info@ficenca.com"
                 style="font-size:14px;">
                <i class="fas fa-envelope"></i>
                </v-btn>

                <v-btn icon text small href="https://goo.gl/maps/DddbeYdvZkqiDzXf8" target="_blank"
                 style="font-size:14px;">
                <i class="fas fa-location-arrow"></i>
                </v-btn>

                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://facebook.com/ficencacostarica`">
                    <strong><i class="fab fa-facebook-f"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://instagram.com/ficencacostarica`">
                    <strong><i class="fab fa-instagram"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://www.linkedin.com/company/ficencacr/`">
                    <i class="fab fa-linkedin-in"></i>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://api.whatsapp.com/send?phone=50670188845`">
                    <strong><i class="fab fa-whatsapp"></i></strong>
                </v-btn>
                </div>
            </v-app-bar>
        </div>
        <v-app-bar height="100" flat color="white" style="color:22354b;width:90%;margin:0 auto;display:block;">
            <img src="@/assets/logo.png" width="200">
            <img class="ml-2 hidden-sm-and-down" 
            src="https://www.ficenca.com/wp-content/uploads/2020/09/logo-fiac-.png" width="100">
            <!--BOTONES DE LA BARRA-->
            <v-spacer></v-spacer>
            <div style="margin:0 50px 0 0;" class="hidden-sm-and-down">
                <v-btn style="
                color: #22354b;font-size:18x;font-weight:600;
                text-transform: none;letter-spacing:0;"  rounded
                text
                @click="irPagina('/')"
                >
                    Inicio
                </v-btn>

                <v-btn style="
                color: #22354b;font-size:18x;font-weight:600;
                text-transform: none;letter-spacing:0;"  rounded
                text
                @click="irPagina('/Servicios')"
                >
                    Servicios
                </v-btn>
            <v-badge
                overlap
                content="¡Nuevo!"
                >
                <v-btn @click="irPagina('/Equipos')" style="
                border:solid 1px #22354b;
                color: #22354b;font-size:18x;font-weight:600;
                text-transform: none;letter-spacing:0;"  rounded
                text
                
                >
                    Equipos
                </v-btn>
            </v-badge>
                
            </div>
            <v-btn icon text class="hidden-md-and-up" @click="nav = !nav">
                <i class="fas fa-bars" style="font-size:40px;color:#165198"></i>
            </v-btn>
            <v-btn text rounded class="hidden-sm-and-down" to="/Contactenos"
            style="background:#165198;color:white;padding:20px 30px;letter-spacing:0;
            text-transform:none;font-weight:900
            ">
                Contáctenos
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="nav" app>
            <img src="@/assets/logo.png" width="200" style="margin:50px auto;display:block;">
            <v-list shaped>
            <v-list-item-group
                v-model="ites"
                color="#22354b"
            >
                <v-list-item
                v-for="(item, i) in bars"
                :key="i"
                :to="item.link"
                >
                <v-list-item-content>
                    <v-list-item-title v-text="item.text" style="font-weight:600"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
            </v-list>
            <v-btn text @click="irPagina('/Contactenos')" style="width:90%;margin:10px auto;display:block;
            text-align:center;background:#165198;color:white;letter-spacing:0;
            text-transform:none;font-weight:900
            ">Contáctenos</v-btn>
            <div style="position: absolute;
        bottom: 30px;
        right: 50%;
        margin-right: -50px;">
                <img
            src="https://www.ficenca.com/wp-content/uploads/2020/09/logo-fiac-.png" width="100">
            </div>
        </v-navigation-drawer>
    </div>
</template>

<style>

.acs {
    background: #f1f1f1;
}
</style>

<script>
export default {
    data(){
        return {
            nav:false,ites:0,
            bars:[
                {text: "Inicio", link: "/"},
                {text: "Servicios", link: "/Servicios"},
                {text: "Equipos", link: "/Equipos"},
            ]
        }
    },
    methods:{
        irPagina(e){
            window.open(e, "_self")
        }
    }
}
</script>